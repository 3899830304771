<template>
  <el-row type="flex" justify="center" align="middle" class="login-row">
    <el-col :xs="20" :sm="16" :md="12" :lg="12">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>{{ $t("reset_password.set") }}</span>
        </div>
        <div class="text-center" style="margin: 20px 0">
          <el-button type="primary" @click="setPassword">
            {{ $t("reset_password.set") }}
          </el-button>
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Password from "@/apis/password";

export default {
  metaInfo() {
    return {
      title: "Set Password - " + this.CompanyName
    };
  },

  components: {},

  mixins: [],

  props: [],
  data() {
    return {};
  },
  computed: {
    token() {
      let token = "";
      if (this.$route.query.token) {
        token = this.$route.query.token;
      }
      return token;
    }
  },
  watch: {},

  mounted() {},

  methods: {
    ...mapActions("password", ["sendRestLink"]),
    async setPassword() {
      let token = "";
      let email = "";
      if (this.token !== "") {
        const res = await Password.getPasswordUrl({
          token: this.token
        });
        token = res.token;
        email = res.email;
      }
      this.$router.push({
        name: "ResetPassword",
        query: {
          token,
          email,
          isset: "1"
        }
      });
    }
  }
};
</script>

<style scoped>
.login-row {
  height: 80vh;
}

.el-card /deep/ .el-card__header {
  background: var(--themeColor);
}
.el-card /deep/ .el-button {
  font-size: 20px;
}
.clearfix {
  color: white;
}
</style>
